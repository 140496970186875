import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Warehouse } from '../api/Types';

interface AppContextState {
  warehouse: Warehouse | null;
}
const initialState: AppContextState = {
  warehouse: null
};

export const appContext = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    pickWarehouse: (state, action: PayloadAction<Warehouse | null>) => {
      state.warehouse = action.payload;
    }
  }
});

export default appContext.reducer;

export const { pickWarehouse } = appContext.actions;
