import Documents from '../../components/list/Documents';
import { useParams } from 'react-router-dom';
import { documentTypesApi } from '../../redux/api/DocumentTypesApi';
import FullScreenLoader from '../../components/ui/FullScreenLoader';

export default function ListDocuments() {
  const params = useParams() as { typeId?: string };
  const {
    isFetching,
    isLoading,
    data: documentType
  } = documentTypesApi.endpoints?.getItem.useQuery(
    { id: params.typeId ?? '' },
    { skip: !params.typeId }
  );

  const loading = isFetching || isLoading;

  if (loading) {
    return <FullScreenLoader />;
  }
  return (
    <Documents
      key={'Documents' + (params.typeId ?? '__defaultDocuments')}
      documentType={params.typeId && !loading ? documentType : undefined}
    />
  );
}
