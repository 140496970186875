import { Alert } from '@mui/material';
import { Dangerous } from '@mui/icons-material';

interface ErrorBoxProps {
  content?: string;
}
export default function ErrorBox(props: ErrorBoxProps) {
  return (
    <Alert variant="standard" color="error" icon={<Dangerous />}>
      {props.content ?? 'Wystąpił jakiś błąd'}
    </Alert>
  );
}

export function ErrorBox404(props: ErrorBoxProps) {
  return <ErrorBox content={props.content} />;
}

ErrorBox404.defaultProps = {
  content: 'Nie znaleziono strony'
};
