import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useGetDocumentTypeAllQuery } from '../redux/api/DocumentTypesApi';
import { DocumentTypeList } from '../redux/api/Types';
import { useNavigate } from 'react-router-dom';
import { getDocumentTypeColor } from '../utils/Colors';

const DocumentTypeBlock = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  color: 'white'
}));

export default function Dashboard() {
  const navigate = useNavigate();
  const { isLoading, isFetching, data } = useGetDocumentTypeAllQuery(null);

  if (isLoading || isFetching) {
    return <h1>Loading...</h1>;
  }

  if (data === undefined) {
    return <h1>Something went wrong...</h1>;
  }

  const handleDocumentTypeClick = (documentType: Pick<DocumentTypeList, 'id'>) => {
    navigate(`/app/warehouse/documents/create-new/${documentType.id}`);
  };
  return (
    <>
      <Grid
        container
        spacing={3}
        textAlign="center"
        justifyContent="space-between"
        sx={{ p: 2, pt: 0 }}>
        <Grid item xs={12}>
          <h4>Wybierz typ dokumentu do dodania</h4>
        </Grid>
        {data['hydra:member'].map((documentType: DocumentTypeList) => (
          <Grid item key={'DocumentTypeBlock_' + documentType['@id']} xs={4} sm={3} md={2} lg={1}>
            <DocumentTypeBlock
              sx={{ backgroundColor: getDocumentTypeColor(documentType.name) }}
              onClick={() => handleDocumentTypeClick(documentType)}>
              {documentType.code}
            </DocumentTypeBlock>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        spacing={3}
        textAlign="center"
        justifyContent="space-between"
        sx={{ p: 2, pt: 0 }}>
        <Grid item xs={12}>
          <DocumentTypeBlock
            onClick={() => navigate(`/app/warehouse/scanner-test`)}
            sx={{ backgroundColor: '#303030' }}>
            Kliknij aby przetestować działanie skanera
          </DocumentTypeBlock>
        </Grid>
        <Grid item xs={12}>
          <DocumentTypeBlock
            onClick={() => navigate(`/app/warehouse/scanner-test-old`)}
            sx={{ backgroundColor: '#303030' }}>
            Kliknij aby przetestować działanie skanera (aktualny)
          </DocumentTypeBlock>
        </Grid>
      </Grid>
    </>
  );
}
