import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contractor, IUser, LoginResponse, Place, ProductWarehouse, UserToken } from '../api/Types';
import jwt_decode from 'jwt-decode';
import { DocumentForm, PlaceQuantity } from '../../components/form/WarehouseDocument/Types';
import { computeVat } from '../../utils/Math';
import dayjs from 'dayjs';

interface DocumentFormState {
  form: Partial<DocumentForm>;
}

type DocumentRowUpdate<P> = {
  documentRowIndex: number;
} & P;

type DocumentRowPlaceUpdate<P> = DocumentRowUpdate<P> & {
  placeRowIndex: number;
};

const initialState = (): DocumentFormState => ({
  form: {
    rows: [],
    issueDate: dayjs().format('YYYY-MM-DD')
  }
});

export const documentFormSlice = createSlice({
  initialState: initialState(),
  name: 'documentFromSlice',
  reducers: {
    initForm: (state, action: PayloadAction<Partial<DocumentForm>>) => {
      state.form = action.payload;
    },
    setIssueDate: (state, action: PayloadAction<string>) => {
      state.form.issueDate = action.payload;
    },
    setSupplier: (state, action: PayloadAction<Contractor | null>) => {
      state.form.supplier = action.payload;
    },
    setRecipient: (state, action: PayloadAction<Contractor | null>) => {
      state.form.recipient = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.form.currency = action.payload;
    },
    setStocktaking: (state, action: PayloadAction<boolean>) => {
      state.form.stocktaking = action.payload;
    },
    setSkipStockValidation: (state, action: PayloadAction<boolean>) => {
      state.form.skipStockValidation = action.payload;
    },
    removeRow: (state, action: PayloadAction<number>) => {
      state.form.rows?.splice(action.payload, 1);
    },
    removePlaceFromRow: (state, action: PayloadAction<DocumentRowPlaceUpdate<{}>>) => {
      const { documentRowIndex, placeRowIndex } = action.payload;
      state.form.rows?.[action.payload.documentRowIndex]?.places?.splice?.(
        action.payload.placeRowIndex,
        1
      );
    },
    updateRowPrices: (
      state,
      action: PayloadAction<DocumentRowUpdate<{ netto: number; brutto: number; vat: number }>>
    ) => {
      const { documentRowIndex, netto, brutto, vat } = action.payload;
      if (!state.form.rows?.[documentRowIndex]) {
        return;
      }

      state.form.rows[documentRowIndex].unitPriceNet = netto;
      state.form.rows[documentRowIndex].unitPriceGross = brutto;
      state.form.rows[documentRowIndex].vat = vat;
    },
    addPlaceToRow: (state, action: PayloadAction<number>) => {
      if (!state.form.rows?.[action.payload]) {
        return;
      }
      state.form.rows[action.payload].places.push({
        place: null,
        quantity: 1,
        isNew: true
      });
    },
    updatePlaceRow: (
      state,
      action: PayloadAction<DocumentRowPlaceUpdate<{ field: keyof PlaceQuantity; data: any }>>
    ) => {
      const { documentRowIndex, placeRowIndex, data, field } = action.payload;

      if (!state.form.rows?.[documentRowIndex]?.places?.[placeRowIndex]) {
        return;
      }
      const placeRow: PlaceQuantity = state.form.rows[documentRowIndex].places[placeRowIndex];
      if (placeRow.hasOwnProperty(field)) {
        (placeRow[field] as any) = data as any;
      }
    },
    handleProductScan: (state, action: PayloadAction<ProductWarehouse>) => {
      if (action.payload === null) {
        return;
      }
      if (state.form.rows === undefined) {
        state.form.rows = [];
      }
      const row = (state.form?.rows ?? []).find(
        (row) => row.product['@id'] === action.payload['@id']
      );
      if (row === undefined) {
        state.form.rows.push({
          product: action.payload,
          unitPriceNet: action.payload.lastSupplyPrice ?? 0,
          unitPriceGross: action.payload.lastSupplyPriceWithTax ?? 0,
          vat:
            computeVat(
              action.payload.lastSupplyPrice ?? 0,
              action.payload.lastSupplyPriceWithTax ?? 0
            ) ?? 23,
          places: [{ place: null, quantity: 1, isNew: true }]
        });
      } else {
        row.places[row.places.length - 1].quantity++;
      }
    },
    resetForm: () => initialState()
  }
});

export default documentFormSlice.reducer;

export const {
  resetForm,
  initForm,
  setCurrency,
  setStocktaking,
  setRecipient,
  removeRow,
  addPlaceToRow,
  setIssueDate,
  updateRowPrices,
  setSkipStockValidation,
  setSupplier,
  handleProductScan,
  updatePlaceRow,
  removePlaceFromRow
} = documentFormSlice.actions;
