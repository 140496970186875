import { createApi } from '@reduxjs/toolkit/query/react';
import { refreshableBaseQuery } from './ApiHelpers';
import {
  ProductType,
  GenericApiPlatformCollectionQueryParameters,
  GenericApiPlatformCollectionResponse
} from './Types';

export const productTypesApi = createApi({
  reducerPath: 'productType',
  baseQuery: refreshableBaseQuery,
  tagTypes: ['ProductType'],
  endpoints: (builder) => ({
    getPaginatedList: builder.query<
      GenericApiPlatformCollectionResponse<ProductType>,
      GenericApiPlatformCollectionQueryParameters
    >({
      query(params) {
        return {
          url: '/manufacture/product-types',
          params
        };
      }
    }),
    getAll: builder.query<GenericApiPlatformCollectionResponse<ProductType>, null>({
      query() {
        return {
          url: '/manufacture/product-types?page=1&limit=1000'
        };
      }
    })
  })
});

export const {
  useGetPaginatedListQuery: useGetProductTypePaginatedListQuery,
  useGetAllQuery: useGetProductTypeAllQuery
} = productTypesApi;
