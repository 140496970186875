import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser, LoginResponse, UserToken } from '../api/Types';
import jwt_decode from 'jwt-decode';

interface IUserState {
  user: IUser | null;
  token: string | null;
  refreshToken: string | null;
  roles: string[];
}

const initialState = (): IUserState => ({
  user: null,
  token: null,
  refreshToken: null,
  roles: []
});

export const userSlice = createSlice({
  initialState: initialState(),
  name: 'userSlice',
  reducers: {
    login: (state, action: PayloadAction<LoginResponse>) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refresh_token;
      const tokenData = jwt_decode(action.payload.token) as UserToken;
      state.user = tokenData.details;
      state.roles = tokenData.roles;
    },
    logout: () => initialState(),
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    }
  }
});

export default userSlice.reducer;

export const { logout, login, setUser } = userSlice.actions;
