import { createApi } from '@reduxjs/toolkit/query/react';
import { refreshableBaseQuery } from './ApiHelpers';
import {
  GenericApiPlatformCollectionQueryParameters,
  GenericApiPlatformCollectionResponse,
  WarehouseDocument
} from './Types';

export const documentsApi = createApi({
  reducerPath: 'document',
  baseQuery: refreshableBaseQuery,
  tagTypes: ['Document'],
  endpoints: (builder) => ({
    getPaginatedList: builder.query<
      GenericApiPlatformCollectionResponse<WarehouseDocument>,
      GenericApiPlatformCollectionQueryParameters
    >({
      query(params) {
        return {
          url: '/warehouse/documents',
          params
        };
      }
    }),
    getAll: builder.query<GenericApiPlatformCollectionResponse<WarehouseDocument>, null>({
      query() {
        return {
          url: '/warehouse/documents'
        };
      }
    }),
    getItem: builder.query<WarehouseDocument, { id: string }>({
      query(params) {
        return {
          url: `/warehouse/documents/${params.id}?disableCustomFields=true`
        };
      }
    }),
    createDocument: builder.mutation<WarehouseDocument, Partial<WarehouseDocument>>({
      query(data) {
        return {
          url: '/warehouse/documents',
          method: 'POST',
          body: data
        };
      }
    }),
    updateDocument: builder.mutation<
      WarehouseDocument,
      { '@id': string; data: Partial<WarehouseDocument> }
    >({
      query(data) {
        return {
          url: data['@id'],
          method: 'PUT',
          body: data.data
        };
      }
    })
  })
});

export const {
  useGetPaginatedListQuery,
  useGetAllQuery,
  useGetItemQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation
} = documentsApi;
