import { DocumentStatus, DocumentType, Warehouse, WarehouseDocument } from '../../redux/api/Types';
import TextField from '@mui/material/TextField';
import { Box, Button, Grid, InputAdornment, Menu, MenuItem, Modal } from '@mui/material';
import DocumentTypeListPicker from '../form/Common/DocumentTypeListPicker';
import Typography from '@mui/material/Typography/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  documentsApi,
  useGetPaginatedListQuery,
  useUpdateDocumentMutation
} from '../../redux/api/DocumentsApi';
import { getDocumentTypeColor } from '../../utils/Colors';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ArrowDownward, MoreVert, WarehouseOutlined } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import DocumentStatusLabel from '../form/SelectLabels/DocumentStatusLabel';

interface DocumentsProps {
  documentType?: DocumentType;
}

const DocumentBox = (props: { document: WarehouseDocument }) => {
  const { document } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<DocumentStatus>(document.status);
  const [updateDocument, { isLoading, isError, isSuccess }] = useUpdateDocumentMutation();
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateDocumentStatus = (status: DocumentStatus) => {
    setStatus(status);
    updateDocument({ '@id': document['@id'], data: { status: status } });
    handleClose();
    handleModalClose();
  };
  return (
    <Box key={document['@id']} sx={(theme) => ({ p: 1, height: '100%' })}>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{ background: '#fff', p: 2, position: 'absolute', bottom: 0 }}>
          <Grid container flexWrap="wrap" spacing={1}>
            <Grid item xs={6} textAlign="center">
              <Button color="success" onClick={() => updateDocumentStatus(2000)}>
                Zaakceptuj
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Button color="error" onClick={() => updateDocumentStatus(3000)}>
                Odrzuć
              </Button>
            </Grid>
            <Grid item xs={12} textAlign="center" marginTop={3}>
              <Button color="primary" onClick={handleModalClose}>
                Zamknij bez zapisywania
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box
        sx={(theme) => ({
          border: `2px solid ${
            typeof document.type === 'object'
              ? getDocumentTypeColor(document.type.name)
              : theme.palette.primary.main
          }`,
          p: 0,
          borderRadius: 2,
          height: '100%'
        })}>
        <Grid
          container
          spacing={0}
          flexDirection="row"
          justifyContent="space-between"
          sx={{ height: '100%' }}>
          <Grid item xs={12} sx={{ overflowX: 'auto' }}>
            <Grid
              container
              xs={12}
              wrap="wrap"
              spacing={0}
              columnSpacing={0}
              rowSpacing={0}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                background: '#303030',
                color: '#fff'
              }}>
              <Grid
                item
                xs={5}
                sx={(theme) => ({
                  textAlign: 'center',
                  padding: 1
                })}>
                {document.number}
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Button
                  id="basic-button"
                  aria-controls={menuOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? 'true' : undefined}
                  onClick={handleClick}>
                  <MoreVert />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}>
                  {document.status === 1000 && (
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleModalOpen();
                      }}>
                      Zmień status
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      navigate(`/app/warehouse/documents/edit/${document.id}`);
                      handleClose();
                      handleClose();
                    }}>
                    Edytuj
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid
                xs={6}
                item
                sx={(theme) => ({
                  textAlign: 'center',
                  padding: 1
                })}>
                {dayjs(document.issueDate).locale('pl').format('lll')}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <DocumentStatusLabel status={status} />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                textAlign: 'center'
              }}>
              <div style={{ width: '100%' }}>
                {typeof document.supplier === 'object'
                  ? document.supplier?.name ?? 'Brak dostawcy'
                  : ''}
              </div>
              <div style={{ width: '100%' }}>
                <ArrowDownward />
              </div>
              <div style={{ width: '100%' }}>
                {typeof document.recipient === 'object'
                  ? document.recipient?.name ?? 'Brak odbiorcy'
                  : ''}
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          {typeof document.type === 'object' && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: getDocumentTypeColor(document.type.name),
                  color: '#fff',
                  textAlign: 'center',
                  padding: 1
                }}>
                {document.type.name}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default function Documents(props: DocumentsProps) {
  const [documents, setDocuments] = useState<WarehouseDocument[]>([]);
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const [searchText, setSearchText] = useState<string>('');
  const [delay, setDelay] = useState<boolean>(false);
  const [useWarehouseFilter, setUseWarehouseFilter] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const { isLoading, isFetching, data, refetch, isUninitialized } = useGetPaginatedListQuery(
    {
      number: searchText,
      'type.id': props.documentType?.id ?? undefined,
      'warehouse.id': useWarehouseFilter ? selectedWarehouse?.id ?? undefined : undefined,
      'order[createdAt]': 'DESC',
      page: page,
      useWarehouseFilter: useWarehouseFilter
    },
    { skip: delay }
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (page === 1) {
      setDocuments(data?.['hydra:member'] ?? []);
    } else {
      setDocuments((prev) => {
        const rawIds = prev.map((item) => item['@id']);
        const newItems = (data?.['hydra:member'] ?? []).filter(
          (item) => !rawIds.includes(item['@id']) ?? []
        );
        return [...prev, ...newItems];
      });
    }
  }, [JSON.stringify(data?.['hydra:member'] ?? []), page]);

  useEffect(() => {
    return () => {
      dispatch(documentsApi.util.invalidateTags(['Document']));
      dispatch(documentsApi.util.resetApiState());
    };
  }, []);

  useEffect(() => {
    return () => {
      setDocuments([]);
      dispatch(documentsApi.util.resetApiState());
    };
  }, [selectedWarehouse]);

  const toggleWarehouseFilter = () => {
    setUseWarehouseFilter(!useWarehouseFilter);
    setPage(1);
  };

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDelay(true);
    setSearchText(event.target.value);
    if (window.documentsTimeout) {
      clearTimeout(window.documentsTimeout);
    }
    window.documentsTimeout = setTimeout(() => {
      setDelay(false);
    }, 500);
  };

  return (
    <div>
      <TextField
        onChange={handleSearchTextChange}
        placeholder={'Szukaj...'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DocumentTypeListPicker
                defaultValue={props.documentType}
                clearable={true}
                onChange={(newDocumentType) => {
                  if (newDocumentType) {
                    navigate(`/app/warehouse/documents/list/${newDocumentType.id}`);
                  } else {
                    navigate(`/app/warehouse/documents/list`);
                  }
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="start"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleWarehouseFilter();
              }}>
              <WarehouseOutlined style={{ color: useWarehouseFilter ? '#303030' : '#dedede' }} />
            </InputAdornment>
          ),
          inputMode: 'numeric',
          inputProps: { pattern: '[0-9]*' }
        }}
      />
      <Grid container spacing={0} sx={{ mt: 1 }} flexWrap="wrap">
        <InfiniteScroll
          dataLength={documents.length} //This is important field to render the next data
          next={() => setPage(page + 1)}
          hasMore={data?.['hydra:view']?.['hydra:next'] !== undefined}
          loader={<>{(isFetching || isLoading) && <h4>Pobieram...</h4>}</>}
          height={'calc(100vh - 150px)'}
          style={{ flexWrap: 'wrap', display: 'flex' }}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <Typography variant="body2">
                To już wszystkie wyniki, jeśli szukałeś czegoś innego spróbuj zmienić parametry
                wyszukiwania
              </Typography>
            </p>
          }>
          {documents.map((document) => (
            <Grid item xs={12} sm={6} md={4} key={document['@id']}>
              <DocumentBox document={document} />
            </Grid>
          ))}
        </InfiniteScroll>
      </Grid>
    </div>
  );
}
