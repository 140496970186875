import { Contractor, Product, ProductWarehouse } from '../../../redux/api/Types';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';

function ProductLabel({ name, ean, symbol }: Pick<ProductWarehouse, 'name' | 'ean' | 'symbol'>) {
  return (
    <div>
      <Typography variant="body1" color="text.primary" align="center">
        {name}
      </Typography>
      <Grid flexWrap="wrap" justifyContent="space-between" sx={{ display: 'flex' }}>
        <Grid item={true}>
          <Typography variant="body2" color="text.secondary">
            {ean}&nbsp;
          </Typography>
        </Grid>
        <Grid item={true}>
          <Typography variant="body2" color="text.secondary">
            {symbol}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(ProductLabel);
