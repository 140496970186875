import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { mainListItems } from './listItems';
import { Grid, Menu, MenuItem, SwipeableDrawer } from '@mui/material';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { AccountCircle, AddCircle, ListAltRounded, Logout } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { logout } from '../redux/features/UserSlice';
import { useAppDispatch, useAppSelector } from '../redux/Store';
import SingleListPicker from '../components/form/Common/SingleListPicker';
import { Warehouse } from '../redux/api/Types';
import { warehousesApi } from '../redux/api/WarehousesApi';
import { pickWarehouse } from '../redux/features/AppContext';
import { documentTypesApi } from '../redux/api/DocumentTypesApi';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { getDocumentTypeColor } from '../utils/Colors';
import { authApi } from '../redux/api/AuthApi';
import { userApi } from '../redux/api/UserApi';
import { productsApi } from '../redux/api/ProductsApi';

const drawerWidth: number = 200;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export default function App() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openUserMenu = Boolean(anchorEl);
  const queryConfig = {
    skip: false,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true
  };
  const { data: warehouses } = warehousesApi.endpoints.getAll.useQuery(null, queryConfig);
  const { data: documentTypes } = documentTypesApi.endpoints.getAll.useQuery(null, queryConfig);
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const pathnames = location.pathname.split('/').filter((x) => x);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logOut = () => {
    dispatch(logout());
    dispatch(authApi.util?.resetApiState());
    dispatch(userApi.util?.resetApiState());
    dispatch(productsApi.util?.resetApiState());
    dispatch(documentTypesApi.util?.resetApiState());
  };

  const handleSelectWarehouse = (value: Warehouse | null) => {
    dispatch(pickWarehouse(value));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              ...(open && { display: 'none' })
            }}>
            <MenuIcon />
          </IconButton>
          <Grid sx={{ flexGrow: 1, textAlign: 'center' }}>
            <SingleListPicker<Warehouse>
              defaultValue={selectedWarehouse}
              options={warehouses?.['hydra:member']}
              onChange={handleSelectWarehouse}
              placeholder="Wybierz magazyn"
              label={(item) =>
                !item ? (
                  ''
                ) : (
                  <div style={{ fontSize: '0.9em' }}>
                    {item.symbol} - <small>{item.name}</small>
                  </div>
                )
              }
            />
          </Grid>

          <IconButton
            color="inherit"
            onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openUserMenu}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem onClick={logOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Wyloguj się
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            width: drawerWidth
          }}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {(documentTypes?.['hydra:member'] ?? []).map((item) => (
            <ListItemButton
              sx={{ justifyContent: 'space-between', textAlign: 'center' }}
              key={'ListItemButton_' + item['@id']}>
              <RouterLink to={`/app/warehouse/documents/create-new/${item.id}`} key={item['@id']}>
                <ListItemIcon
                  sx={{
                    color: getDocumentTypeColor(item.name),
                    minWidth: 'initial',
                    pl: 1,
                    pr: 1
                  }}>
                  <AddCircle fontSize="large" />
                </ListItemIcon>
              </RouterLink>
              <ListItemText primary={item.code} />
              <RouterLink to={`/app/warehouse/documents/list/${item.id}`} key={item['@id']}>
                <ListItemIcon
                  sx={{
                    color: getDocumentTypeColor(item.name),
                    minWidth: 'initial',
                    pl: 1,
                    pr: 1
                  }}>
                  <ListAltRounded fontSize="large" />
                </ListItemIcon>
              </RouterLink>
            </ListItemButton>
          ))}
        </List>
      </SwipeableDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: 'calc(100vh - 58px)',
          overflow: 'auto',
          marginTop: '58px'
        }}>
        <Container maxWidth="lg" style={{ padding: 0 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}
