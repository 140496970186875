import { createApi } from '@reduxjs/toolkit/query/react';
import { refreshableBaseQuery } from './ApiHelpers';
import {
  Contractor,
  GenericApiPlatformCollectionQueryParameters,
  GenericApiPlatformCollectionResponse
} from './Types';

export const contractorsApi = createApi({
  reducerPath: 'contractors',
  baseQuery: refreshableBaseQuery,
  tagTypes: ['contractors'],
  endpoints: (builder) => ({
    getPaginatedList: builder.query<
      GenericApiPlatformCollectionResponse<Contractor>,
      GenericApiPlatformCollectionQueryParameters
    >({
      query(params) {
        return {
          url: '/contractor/contractors',
          params
        };
      }
    }),
    getAll: builder.query<GenericApiPlatformCollectionResponse<Contractor>, null>({
      query() {
        return {
          url: '/contractor/contractors?page=1&limit=1000'
        };
      }
    })
  })
});
