import { Paper, Typography, useTheme } from '@mui/material';

export default function DocumentStatusLabel(props: { status: number }) {
  const { status } = props;
  const theme = useTheme();
  let color =
    {
      1000: '#c5cae9',
      2000: '#1b5e20',
      2100: theme.palette.primary.main,
      3000: '#d50000'
    }[status] ?? '#c5cae9';

  return (
    <Paper
      sx={(theme) => ({
        backgroundColor: color,
        p: '2px',
        borderRadius: `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`
      })}>
      <DocumentStatusText status={status} color="white" />
    </Paper>
  );
}

export function DocumentStatusText(props: { status: number; color?: string }) {
  const { status, color } = props;

  switch (status) {
    case 1000:
      return <Typography sx={{ color: color ?? 'red' }}>Oczekujący</Typography>;
    case 2000:
      return <Typography sx={{ color: color ?? 'green' }}>Zatwierdzony</Typography>;
    case 3000:
      return <Typography sx={{ color: color ?? 'red' }}>Odrzucony</Typography>;
    default:
      return <Typography sx={{ color: color ?? 'red' }}>Nieznany</Typography>;
  }
}
