import { Place, Product } from '../../../redux/api/Types';
import { memo, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { PlaceQuantity, DocumentRowForm, Price } from './Types';
import PlaceRow from './PlaceRow';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import NettoBruttoInput from '../Common/NettoBruttoInput';
import ProductLabel from '../SelectLabels/ProductLabel';
import { DeleteForever } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { shallowEqual } from 'react-redux';
import {
  addPlaceToRow,
  removeRow,
  setCurrency,
  updateRowPrices
} from '../../../redux/features/DocumentFormSlice';

interface ProductBoxProps {
  documentRowIndex: number;
}

const NumericField = (
  props: {
    variant?: any;
  } & Omit<TextFieldProps, 'variant'>
) => (
  <TextField type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} {...props} />
);

const ProductBoxNettoBruttoInput = (props: { documentRowIndex: number }) => {
  const { vat, unitPriceNet, unitPriceGross } = useAppSelector((state) => {
    const documentRow: DocumentRowForm | null =
      state.documentForm.form.rows[props.documentRowIndex];

    if (!documentRow) {
      return {
        unitPriceNet: 0,
        unitPriceGross: 0,
        vat: 0
      };
    }

    return {
      unitPriceNet: documentRow.unitPriceNet,
      unitPriceGross: documentRow.unitPriceGross,
      vat: documentRow.vat
    };
  }, shallowEqual);
  const currency = useAppSelector(
    (state) => state.documentForm.form?.currency ?? 'PLN',
    shallowEqual
  );

  const dispatch = useAppDispatch();

  const handlePricesChange = useCallback((netto: number, brutto: number, vat: number) => {
    dispatch(updateRowPrices({ documentRowIndex: props.documentRowIndex, netto, brutto, vat }));
  }, []);

  const onCurrencyChange = useCallback((currency: string) => {
    dispatch(setCurrency(currency));
  }, []);

  return (
    <NettoBruttoInput
      vat={vat}
      netto={unitPriceNet}
      brutto={unitPriceGross}
      onChange={handlePricesChange}
      onCurrencyChange={onCurrencyChange}
      currency={currency}
    />
  );
};

const RowProductLabel = (props: { documentRowIndex: number }) => {
  const product = useAppSelector(
    (state) => state.documentForm.form.rows[props.documentRowIndex]?.product,
    shallowEqual
  );

  if (!product) {
    return null;
  }

  return <ProductLabel {...product} />;
};

function ProductBox(props: ProductBoxProps) {
  const rows: number = useAppSelector(
    (state) => (state.documentForm.form?.rows?.[props.documentRowIndex].places ?? []).length
  );

  const placesMap = [];

  for (let i = 0; i < rows; i++) {
    placesMap.push(i);
  }

  const documentRowForm: DocumentRowForm = useAppSelector(
    (state) => (state.documentForm.form.rows ?? [])[props.documentRowIndex],
    shallowEqual
  );

  const dispatch = useAppDispatch();

  // const [places, setPlaces] = useState<PlaceQuantity[]>(places1);
  // const propPlaces = JSON.stringify(places1);
  // const statePlaces = JSON.stringify(places);
  // const [newPlaceProcessed, setNewPlaceProcessed] = useState<boolean>(places1.length > 0);
  //
  // useEffect(() => {
  //   if (!newPlaceProcessed) {
  //     setNewPlaceProcessed(true);
  //     addNewPlace();
  //   }
  // }, [newPlaceProcessed]);
  // useEffect(() => {
  //   if (propPlaces !== statePlaces) {
  // props.onUpdate?.(product, places);
  // }
  // }, [statePlaces, places.length, product]);
  // useEffect(() => {
  //   if (propPlaces !== statePlaces) {
  //     setPlaces(places1);
  //   }
  // }, [propPlaces]);

  // const handleAdd = useCallback((index: number) => {
  //   setPlaces((prevState) => {
  //     return prevState.map((place, i) => {
  //       if (i === index) {
  //         return {
  //           ...place,
  //           quantity: place.quantity + 1
  //         };
  //       }
  //       return place;
  //     });
  //   });
  // }, []);
  // const handleRemove = useCallback((index: number) => {
  //   setPlaces((prevState) => {
  //     return prevState.map((place, i) => {
  //       if (i === index) {
  //         return {
  //           ...place,
  //           quantity: Math.max(place.quantity - 1, 0)
  //         };
  //       }
  //       return place;
  //     });
  //   });
  // }, []);
  //
  // const handlePlaceSelect = useCallback((index: number, selectedPlace: Place | null) => {
  //   setPlaces((prevState) => {
  //     return prevState.map((place, i) => {
  //       if (i === index) {
  //         return {
  //           ...place,
  //           place: selectedPlace,
  //           isNew: false
  //         };
  //       }
  //       return place;
  //     });
  //   });
  // }, []);
  //
  const addNewPlace = useCallback(() => {
    dispatch(addPlaceToRow(props.documentRowIndex));
  }, []);
  //
  // const removePlace = (index: number) => {
  //   setPlaces((prevState) => prevState.filter((_, i) => i !== index));
  // };
  //
  const handleRemoveProduct = useCallback(() => {
    dispatch(removeRow(props.documentRowIndex));
  }, []);
  //
  // const handlePricesChange = useCallback(
  //   (netto: number, brutto: number, vat: number) => {
  //     props.onUpdatePrice?.(product, { netto, brutto, vat });
  //   },
  //   [JSON.stringify({ product })]
  // );

  return (
    <Grid item={true} xs={12} sm={6} lg={4} sx={{ height: '100%', p: 1 }}>
      <Card>
        <CardContent sx={{ position: 'relative' }}>
          <RowProductLabel documentRowIndex={props.documentRowIndex} />
          <ProductBoxNettoBruttoInput documentRowIndex={props.documentRowIndex} />

          <DeleteForever
            color="error"
            onClick={handleRemoveProduct}
            sx={{ position: 'absolute', top: 5, right: 5 }}
          />
        </CardContent>
        <CardContent sx={{ p: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Miejsce</TableCell>
                <TableCell align="center">Dost.</TableCell>
                <TableCell align="center" colSpan={3}>
                  Ilość szt.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {placesMap.map((placeIndex) => (
                <PlaceRow
                  key={`document_row_${props.documentRowIndex}_place_row_${placeIndex}`}
                  placeRowIndex={placeIndex}
                  documentRowIndex={props.documentRowIndex}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <Button onClick={addNewPlace}>Dodaj miejsce magazynowe</Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default memo(ProductBox);
