import { Container, Grid, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import CurrencyListPicker, { CurrencyResource } from './CurrencyListPicker';

interface NettoBruttoInputProps {
  netto: number | null;
  brutto: number | null;
  vat: number | null;
  currency?: string;
  onChange?: (netto: number, brutto: number, vat: number) => void;
  onCurrencyChange?: (currency: string) => void;
}

function NettoBruttoInput(props: NettoBruttoInputProps) {
  const { netto, brutto, vat, onChange, currency, onCurrencyChange } = props;

  const [nettoValue, setNettoValue] = useState<number>(netto ?? 0);
  const [bruttoValue, setBruttoValue] = useState<number>(brutto ?? 0);
  const [vatValue, setVatValue] = useState<number>(vat ?? 23);

  useEffect(() => {
    setNettoValue(netto ?? 0);
  }, [netto ?? 0]);
  useEffect(() => {
    setBruttoValue(brutto ?? 0);
  }, [brutto ?? 0]);
  useEffect(() => {
    setVatValue(vat ?? 0);
  }, [vat ?? 0]);

  const vatMultiplier = 1 + vatValue / 100;

  const handleNettoChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newNettoValue = parseFloat(
        (event.target.value ? parseFloat(event.target.value) : 0).toFixed(2)
      );
      const newBruttoValue = parseFloat((newNettoValue * vatMultiplier).toFixed(2));
      setNettoValue(newNettoValue);
      setBruttoValue(newBruttoValue);
      onChange?.(newNettoValue, newBruttoValue, vatValue);
    },
    [onChange, vatMultiplier]
  );
  const handleBruttoChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newBruttoValue = parseFloat(
        (event.target.value ? parseFloat(event.target.value) : 0).toFixed(2)
      );
      const newNettoValue = parseFloat((newBruttoValue / vatMultiplier).toFixed(2));
      setBruttoValue(newBruttoValue);
      setNettoValue(newNettoValue);
      onChange?.(newNettoValue, newBruttoValue, vatValue);
    },
    [onChange, vatMultiplier]
  );
  const handleVatChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newVatValue = parseFloat(
        (event.target.value ? parseFloat(event.target.value) : 0).toFixed(2)
      );
      const newVatMultiplier = 1 + newVatValue / 100;
      const newBruttoValue = parseFloat((nettoValue * newVatMultiplier).toFixed(2));
      setVatValue(newVatValue);
      setBruttoValue(newBruttoValue);
      onChange?.(nettoValue, newBruttoValue, newVatValue);
    },
    [onChange, nettoValue]
  );

  const currencyObject: CurrencyResource = useMemo(() => {
    return {
      '@id': currency ?? '',
      id: currency ?? '',
      currency: currency ?? '',
      symbol: currency ?? ''
    };
  }, [currency]);

  const handleCurrencyChange = useCallback(
    (currency: CurrencyResource | null) => {
      if (currency === null) {
        onCurrencyChange?.('PLN');
        return;
      }
      onCurrencyChange?.(currency.id);
    },
    [onCurrencyChange]
  );

  return (
    <>
      <Container sx={{ display: 'flex', justifyContent: 'space-between', p: 0 }}>
        <Grid item={true}>
          <TextField
            label="Netto"
            value={nettoValue === 0 ? '' : nettoValue}
            onChange={handleNettoChange}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CurrencyListPicker
                    defaultValue={currencyObject}
                    onChange={handleCurrencyChange}
                  />
                </InputAdornment>
              ),
              inputMode: 'numeric',
              inputProps: { pattern: '[0-9]*' }
            }}
          />
        </Grid>
        <Grid item={true} sx={{ pr: 1, pl: 1, minWidth: 80, maxWidth: 80 }}>
          <TextField
            label="Vat"
            value={vatValue === 0 ? '' : vatValue}
            onChange={handleVatChange}
            type="number"
            InputProps={{
              inputMode: 'numeric',
              inputProps: { pattern: '[0-9]' }
            }}
          />
        </Grid>
        <Grid item={true}>
          <TextField
            label="Brutto"
            value={bruttoValue === 0 ? '' : bruttoValue}
            onChange={handleBruttoChange}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CurrencyListPicker
                    defaultValue={currencyObject}
                    onChange={handleCurrencyChange}
                  />
                </InputAdornment>
              ),
              inputMode: 'numeric',
              inputProps: { pattern: '[0-9]*' }
            }}
          />
        </Grid>
      </Container>
    </>
  );
}

export default memo(NettoBruttoInput);
