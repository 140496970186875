import { useGetAllWarehouseProductsQuery } from '../redux/api/ProductsApi';
import { ProductWarehouse } from '../redux/api/Types';
import { useMemo } from 'react';

type WarehouseProductsHook = {
  products: ProductWarehouse[];
  isLoading: boolean;
};

type WarehouseProductsHookProps = {
  typeId?: string | null;
};

export const useWarehouseProducts = (
  props: WarehouseProductsHookProps = {}
): WarehouseProductsHook => {
  const { isLoading, isFetching, data, isUninitialized } = useGetAllWarehouseProductsQuery(null);

  return useMemo(() => {
    if (!data?.['hydra:member']) {
      return { isLoading: isLoading || isFetching || isUninitialized, products: [] };
    }

    if (!props.typeId) {
      return {
        isLoading: isLoading || isFetching || isUninitialized,
        products: data['hydra:member']
      };
    }

    return {
      isLoading: isLoading || isFetching || isUninitialized,
      products: (data?.['hydra:member'] ?? []).filter((product: ProductWarehouse) => {
        return product.type?.id === props.typeId;
      })
    };
  }, [
    props.typeId,
    data?.['hydra:member'],
    isLoading,
    isFetching,
    isUninitialized
  ]) as WarehouseProductsHook;
};

export const useWarehouseProduct = (productId: string | null): ProductWarehouse | null => {
  const { products } = useWarehouseProducts();

  return useMemo(() => {
    if (!productId) {
      return null;
    }

    return products.find((product) => product['@id'] === productId) ?? null;
  }, [productId, products]);
};

export const useWarehouseProductQuantity = (
  productId: string | null,
  placeId: string | null
): number => {
  const product = useWarehouseProduct(productId);

  return useMemo(() => {
    if (!product) {
      return 0;
    }

    const allPlaces = product.warehouses.flatMap((warehouse) => warehouse.places);
    const places = allPlaces.filter((place) => place.id === placeId);

    return places.reduce((acc, place) => acc + place.quantity, 0);
  }, [product, productId, placeId]);
};
