import { useAppDispatch, useAppSelector } from '../../redux/Store';
import { useEffect } from 'react';
import { userApi } from '../../redux/api/UserApi';
import { warehousesApi } from '../../redux/api/WarehousesApi';

const HandleWarehouseChange = () => {
  const warehouse = useAppSelector((state) => state.appContext.warehouse?.['@id']);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(warehousesApi.endpoints?.getPlaces.initiate({ warehouseId: warehouse }));
  }, [warehouse]);

  return <></>;
};

export default function () {
  return <></>;
}
