import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../redux/Store';

const isGranted = (roles: string[], requiredRole: string) => roles.includes(requiredRole);

function useIsGranted(role: string): boolean {
  const { roles }: { roles: string[] } = useAppSelector(
    (state) => ({
      roles: state.userState.roles
    }),
    shallowEqual
  );

  return useMemo(() => role.split('|').some((el) => isGranted(roles, el)), [role, roles]);
}

export default useIsGranted;

/**
 * https://reactjs.org/docs/hooks-custom.html#:~:text=GitHub-,Building%20Your%20Own%20Hooks,-Hooks
 */
export function useIsGrantedFn() {
  const { roles }: { roles: string[] } = useAppSelector(
    (state) => ({
      roles: state.userState.roles
    }),
    shallowEqual
  );

  return (role: string) => role && role.split('|').some((el) => isGranted(roles, el));
}
