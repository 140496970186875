import { createApi } from '@reduxjs/toolkit/query/react';
import { refreshableBaseQuery } from './ApiHelpers';
import { GenericApiPlatformCollectionResponse, ProductWarehouse } from './Types';

export const productsApi = createApi({
  reducerPath: 'products',
  baseQuery: refreshableBaseQuery,
  tagTypes: ['products'],
  endpoints: (builder) => ({
    getAll: builder.query<GenericApiPlatformCollectionResponse<ProductWarehouse>, null>({
      query() {
        return {
          url: '/warehouse/products/all'
        };
      }
    })
  })
});

export const { useGetAllQuery: useGetAllWarehouseProductsQuery } = productsApi;
