import { Place } from '../../../redux/api/Types';
import { useCallback, useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { AddCircleOutline, DeleteForever, RemoveCircleOutline } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import PlaceSinglePicker from '../SinglePickers/PlaceSinglePicker';
import { useAppDispatch, useAppSelector } from '../../../redux/Store';
import { removePlaceFromRow, updatePlaceRow } from '../../../redux/features/DocumentFormSlice';
import {
  useWarehouseProduct,
  useWarehouseProductQuantity,
  useWarehouseProducts
} from '../../../hooks/WarehouseProducts';

const PlaceAvailableQuantity = (props: { place: string | null; product: string | null }) => {
  const quantity = useWarehouseProductQuantity(props.product, props.place);

  return <>{quantity}</>;
};
const PlaceRowPlacePicker = (props: { documentRowIndex: number; placeRowIndex: number }) => {
  const dispatch = useAppDispatch();
  const { documentRowIndex, placeRowIndex } = props;
  const place = useAppSelector(
    (state) =>
      state.documentForm.form?.rows?.[documentRowIndex]?.places?.[placeRowIndex]?.place ?? null
  );
  const isNew = useAppSelector(
    (state) =>
      state.documentForm.form?.rows?.[documentRowIndex]?.places?.[placeRowIndex]?.isNew === true
  );

  const handlePlaceSelect = useCallback(
    (newPlace: Place | null) => {
      dispatch(
        updatePlaceRow({
          documentRowIndex,
          placeRowIndex,
          field: 'place',
          data: newPlace
        })
      );
      dispatch(
        updatePlaceRow({
          documentRowIndex,
          placeRowIndex,
          field: 'isNew',
          data: false
        })
      );
    },
    [documentRowIndex, placeRowIndex]
  );
  const onCancel = useCallback(() => {
    dispatch(
      updatePlaceRow({
        documentRowIndex,
        placeRowIndex,
        field: 'isNew',
        data: false
      })
    );
  }, [documentRowIndex, placeRowIndex]);
  return (
    <PlaceSinglePicker
      defaultValue={place}
      openByDefault={isNew}
      onChange={handlePlaceSelect}
      onCancel={onCancel}
      onClose={onCancel}
    />
  );
};

function PlaceRow(props: { documentRowIndex: number; placeRowIndex: number }) {
  const { documentRowIndex, placeRowIndex } = props;
  const dispatch = useAppDispatch();

  const quantity = useAppSelector(
    (state) =>
      state.documentForm.form?.rows?.[documentRowIndex]?.places?.[placeRowIndex]?.quantity ?? 0
  );
  const productIri = useAppSelector(
    (state) => state.documentForm.form?.rows?.[documentRowIndex]?.product?.['@id'] ?? null
  );
  const placeIri = useAppSelector(
    (state) =>
      state.documentForm.form?.rows?.[documentRowIndex]?.places?.[placeRowIndex]?.place?.id ?? null
  );

  const handleAdd = useCallback(() => {
    dispatch(
      updatePlaceRow({
        documentRowIndex,
        placeRowIndex,
        field: 'quantity',
        data: quantity + 1
      })
    );
  }, [documentRowIndex, placeRowIndex, quantity]);

  const handleRemove = useCallback(() => {
    dispatch(
      updatePlaceRow({
        documentRowIndex,
        placeRowIndex,
        field: 'quantity',
        data: Math.max(quantity - 1, 0)
      })
    );
  }, [documentRowIndex, placeRowIndex, quantity]);

  const handlePlaceRemove = useCallback(() => {
    dispatch(removePlaceFromRow({ documentRowIndex, placeRowIndex }));
  }, [documentRowIndex, placeRowIndex]);

  return (
    <TableRow>
      <TableCell align="center" padding="none">
        <DeleteForever color="error" onClick={handlePlaceRemove} />
      </TableCell>
      <TableCell align="center">
        <PlaceRowPlacePicker placeRowIndex={placeRowIndex} documentRowIndex={documentRowIndex} />
      </TableCell>
      <TableCell align="center">
        <PlaceAvailableQuantity product={productIri} place={placeIri} />
      </TableCell>
      <TableCell align="center" padding="none">
        <IconButton onClick={handleRemove}>
          <RemoveCircleOutline color="error" />
        </IconButton>
      </TableCell>
      <TableCell align="center">{quantity}</TableCell>
      <TableCell align="center" padding="none">
        <IconButton onClick={handleAdd}>
          <AddCircleOutline color="success" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const MemoizedPlaceRow = React.memo(PlaceRow);

export default MemoizedPlaceRow;
