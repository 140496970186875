import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { authApi } from './api/AuthApi';
import { userApi } from './api/UserApi';
import { documentTypesApi } from './api/DocumentTypesApi';
import { warehousesApi } from './api/WarehousesApi';
import { productsApi } from './api/ProductsApi';
import { contractorsApi } from './api/ContractorsApi';
import userReducer from './features/UserSlice';
import appContextReducer from './features/AppContext';
import documentFormReducer from './features/DocumentFormSlice';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { documentsApi } from './api/DocumentsApi';
import { productTypesApi } from './api/ProductTypesApi';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userState', 'appContext']
};

const apis = [
  authApi,
  userApi,
  documentTypesApi,
  warehousesApi,
  productsApi,
  contractorsApi,
  documentsApi,
  productTypesApi
];

const combinedReducer = combineReducers({
  ...apis.reduce((acc: { [key: string]: Reducer }, api) => {
    acc[api.reducerPath] = api.reducer;
    return acc;
  }, {}),
  userState: userReducer,
  appContext: appContextReducer,
  documentForm: documentFormReducer
});
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  return combinedReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([thunk, ...apis.map((api) => api.middleware)])
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
export const Persistor = persistStore(Store);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
