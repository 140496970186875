import { ProductType } from '../../../redux/api/Types';
import SingleListPicker, { SingleListPickerProps } from './SingleListPicker';
import * as React from 'react';
import ProductTypeLabel from '../SelectLabels/ProductTypeLabel';
import { useGetProductTypeAllQuery } from '../../../redux/api/ProductTypesApi';

export default function ProductTypeListPicker(props: Partial<SingleListPickerProps<ProductType>>) {
  const { isLoading, isFetching, data } = useGetProductTypeAllQuery(null);

  if (isLoading || isFetching) {
    return <div>Ładowanie...</div>;
  }

  return (
    <SingleListPicker<ProductType>
      placeholder="Typ"
      {...props}
      options={data?.['hydra:member'] ?? []}
      parseOptionToString={(item) => item.name}
      label={(item) => (!item ? '' : <ProductTypeLabel {...item} />)}
    />
  );
}
