import { Contractor, ProductType } from '../../../redux/api/Types';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';

function ContractorLabel({ name, color }: Pick<ProductType, 'name' | 'color'>) {
  return (
    <Box borderLeft={`10px solid ${color ?? '#fff'}`} paddingLeft={1}>
      <Typography variant="body1" color="text.primary" align="left">
        {name}
      </Typography>
    </Box>
  );
}

export default memo(ContractorLabel);
