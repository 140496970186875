import { createBrowserRouter, Navigate } from 'react-router-dom';
import Login from '../pages/auth/Login';
import Dashboard from '../pages/Dashboard';
import Auth from '../layouts/Auth';
import App from '../layouts/App';
import Index from '../pages/Index';
import RequireLoggedUser from '../components/ui/RequireLoggedUser';
import Blank from '../layouts/Blank';
import CreateNewDocument from '../pages/document/CreateNewDocument';
import ListDocuments from '../pages/document/ListDocuments';
import UpdateDocument from '../pages/document/UpdateDocument';
import ScannerTest from '../pages/ScannerTest';
import ScannerTestOld from '../pages/ScannerTestOld';

export const routes = [
  {
    path: '/',
    element: <Index />
  },
  {
    path: 'auth',
    element: <Auth />,
    children: [
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  {
    path: 'app',
    element: <RequireLoggedUser requiredRoles={['ROLE_EMPLOYEE']} />,
    children: [
      {
        path: 'warehouse',
        element: <App />,
        children: [
          {
            path: 'dashboard',
            element: <Dashboard />
          },
          {
            path: 'scanner-test',
            element: <ScannerTest />
          },
          {
            path: 'scanner-test-old',
            element: <ScannerTestOld />
          },
          {
            path: 'documents',
            element: <Blank />,
            children: [
              {
                path: 'create-new/:typeId',
                element: <CreateNewDocument />
              },
              {
                path: 'edit/:documentId',
                element: <UpdateDocument />
              },
              {
                path: 'list',
                element: <ListDocuments />
              },
              {
                path: 'list/:typeId',
                element: <ListDocuments />
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/app/warehouse/dashboard" replace={true} />
      }
    ]
  }
];

export default createBrowserRouter(routes);
