import { getAllISOCodes } from 'iso-country-currency';
import { ApiPlatformRecordResponse, Product } from '../../../redux/api/Types';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import SingleListPicker, { SingleListPickerProps } from './SingleListPicker';
import * as React from 'react';

export type CurrencyResource = ApiPlatformRecordResponse & {
  currency: string;
  symbol: string;
};

const CURRENCIES_INDEXED = getAllISOCodes().reduce(
  (prev, curr) => {
    prev[curr.currency] = {
      currency: curr.currency,
      symbol: curr.symbol,
      '@id': curr.currency,
      id: curr.currency
    };
    return prev;
  },
  {} as { [key: string]: CurrencyResource }
);

const CURRENCIES = Object.values(CURRENCIES_INDEXED);

export default function CurrencyListPicker(
  props: Partial<SingleListPickerProps<CurrencyResource>>
) {
  return (
    <SingleListPicker<CurrencyResource>
      {...props}
      options={CURRENCIES}
      placeholder="Waluta"
      parseOptionToString={(item) => item.currency + item.symbol}
      label={(item) =>
        !item ? (
          ''
        ) : (
          <div>
            <Typography variant="body1" color="text.primary" align="center">
              {item.currency} ({item.symbol})
            </Typography>
          </div>
        )
      }
      selectedLabel={(item) => item?.symbol ?? ''}
    />
  );
}
