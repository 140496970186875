import { useNavigate, useParams } from 'react-router-dom';
import { documentTypesApi } from '../../redux/api/DocumentTypesApi';
import FullScreenLoader from '../../components/ui/FullScreenLoader';
import { ErrorBox404 } from '../../components/ui/ErrorBox';
import { Alert, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import WarehouseDocumentForm from '../../components/form/WarehouseDocument/WarehouseDocumentForm';
import { useCreateDocumentMutation, useUpdateDocumentMutation } from '../../redux/api/DocumentsApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Warehouse, WarehouseDocument } from '../../redux/api/Types';
import { DocumentForm } from '../../components/form/WarehouseDocument/Types';
import { useAppDispatch, useAppSelector } from '../../redux/Store';
import { useWarehouseProducts } from '../../hooks/WarehouseProducts';
import { userApi } from '../../redux/api/UserApi';
import { initForm } from '../../redux/features/DocumentFormSlice';
import dayjs from 'dayjs';
import { ArrowBackIos, Save } from '@mui/icons-material';
import * as React from 'react';

const ActionButtons = () => {
  const navigate = useNavigate();
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const currentUserId = useAppSelector((state) => state.userState.user.id);
  const documentData: DocumentForm = useAppSelector((state) => state.documentForm.form);

  const [
    createNewDocument,
    {
      isLoading: isSavingNew,
      data: createdDocument,
      isError: isErrorNew,
      error: errorNew,
      isSuccess: isSuccessNew
    }
  ] = useCreateDocumentMutation();

  useEffect(() => {
    if (isSavingNew) {
      toast.info('Zapisywanie w toku', { autoClose: 500 });
      return;
    }
    if (isSuccessNew) {
      toast.success('Utworzono nowy dokument', { autoClose: 2500 });
      navigate(
        `/app/warehouse/documents/list/${
          typeof createdDocument?.type === 'object' ? createdDocument.type?.id : undefined
        }`
      );
    }
    if (isErrorNew) {
      if (((errorNew as any).status as number) === 422) {
        ((errorNew as any).data.violations as any).forEach((el: any) =>
          toast.error(`${el.propertyPath}: ${el.message}`, {
            position: 'top-right',
            autoClose: 4500
          })
        );
      }
    }
  }, [isSavingNew]);
  const onCancel = () => {
    navigate(`/app/warehouse/dashboard`);
  };

  const onSubmit = () => {
    const newDocument: Partial<WarehouseDocument> = {
      recipient: documentData.recipient?.['@id'] ?? null,
      supplier: documentData.supplier?.['@id'] ?? null,
      issuer: `/employee/employees/${currentUserId}`,
      type: documentData.type?.['@id'] ?? null,
      currency: documentData.currency,
      warehouse: selectedWarehouse?.['@id'] ?? null,
      issueDate: documentData.issueDate,
      rows: [
        ...documentData.rows
          .map((row) =>
            row.places.map((place) => ({
              product: row.product?.['@id'] ?? null,
              unit: null,
              quantity: place.quantity,
              place: place.place?.['@id'] ?? null,
              unitPriceNet: Math.round(row.unitPriceNet * 100),
              unitPriceGross: Math.round(row.unitPriceGross * 100),
              vat: row.vat
            }))
          )
          .flat()
      ],
      totalPriceNet: Math.round(computeTotalPrice(documentData.rows, 'unitPriceNet') * 100),
      totalPriceGross: Math.round(computeTotalPrice(documentData.rows, 'unitPriceGross') * 100),
      totalPriceVat: Math.round(computeTotalPrice(documentData.rows, 'unitPriceVat') * 100),
      place: 'Tomaszów Mazowiecki',
      stocktaking: documentData.stocktaking,
      skipStockValidation: documentData.skipStockValidation
    };

    createNewDocument(newDocument);
  };

  const computeTotalPrice = (
    rows: DocumentForm['rows'],
    field: 'unitPriceNet' | 'unitPriceGross' | 'unitPriceVat'
  ) => {
    return rows.reduce((prev, curr) => {
      const quantity = curr.places.reduce((prev, curr) => prev + curr.quantity, 0);
      if (field === 'unitPriceVat') {
        return prev + (curr['unitPriceGross'] - curr['unitPriceNet']) * quantity;
      }
      return prev + curr[field] * quantity;
    }, 0);
  };

  return (
    <Box
      sx={{
        mb: 0,
        width: '100%',
        textAlign: 'center',
        borderRadius: 0,
        borderTop: (theme) => `1px ridge ${theme.palette.primary.main}`,
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 2
      }}>
      <BottomNavigation
        showLabels
        value={null}
        onChange={(event, newValue) => false}
        sx={{ justifyContent: 'space-between' }}>
        <BottomNavigationAction label="Anuluj" icon={<ArrowBackIos />} onClick={onCancel} />
        <BottomNavigationAction label="" icon={null} />
        <BottomNavigationAction label="Zapisz" icon={<Save color="success" />} onClick={onSubmit} />
      </BottomNavigation>
    </Box>
  );
};

export default function () {
  const navigate = useNavigate();
  const params = useParams() as { typeId: string };
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const currentUserId = useAppSelector((state) => state.userState.user.id);
  const {
    isError,
    isFetching,
    isLoading,
    data: documentType
  } = documentTypesApi.endpoints?.getItem.useQuery({ id: params.typeId });
  const { isLoading: isLoadingProducts } = useWarehouseProducts();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isFetching && !isLoading && !isLoadingProducts && documentType !== undefined) {
      dispatch(
        initForm({
          type: documentType,
          rows: [],
          supplier: documentType.defaultSupplier,
          recipient: documentType.defaultRecipient,
          issueDate: dayjs().format('YYYY-MM-DD')
        })
      );
    }
  }, [documentType, isFetching, isLoading]);

  if (isFetching || isLoading || isLoadingProducts) {
    return <FullScreenLoader />;
  }

  if (isError || documentType === undefined) {
    return <ErrorBox404 content="Typ dokumentu nie został odnaleziony" />;
  }

  return (
    <div>
      <WarehouseDocumentForm />
      <ActionButtons />
    </div>
  );
}
