import { FormHelperText, Typography, FormControl, TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type IFormInputProps = {
  name: string;
  label: string;
} & TextFieldProps;

const FormInput: FC<IFormInputProps> = ({ name, label, ...otherProps }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <>
          <TextField label={label} {...field} error={!!errors[name]} {...otherProps} />
          <FormHelperText error={!!errors[name]}>
            {(errors as any)[name]?.message ?? ''}
          </FormHelperText>
        </>
      )}
    />
  );
};

export default FormInput;
