import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useGetDocumentTypeAllQuery } from '../redux/api/DocumentTypesApi';
import { DocumentTypeList, ProductWarehouse, Warehouse } from '../redux/api/Types';
import { useNavigate } from 'react-router-dom';
import { getDocumentTypeColor } from '../utils/Colors';
import useScanner, { useScannerRaw } from '../hooks/Scanner';
import { toast } from 'react-toastify';
import { useWarehouseProducts } from '../hooks/WarehouseProducts';
import { useAppSelector } from '../redux/Store';

const DocumentTypeBlock = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  color: 'white'
}));

export default function ScannerTest() {
  const [scannerData, setScannerData] = React.useState<string[]>([]);
  const selectedWarehouse: Warehouse | null = useAppSelector((state) => state.appContext.warehouse);
  const { isLoading, products } = useWarehouseProducts({
    typeId: selectedWarehouse?.type?.id ?? undefined
  });
  useScannerRaw(
    (data) => {
      if (data.length === 0) {
        toast.error('Brak wyniku ');
        return;
      } else {
        // toast.success('Zeskanowano');
        setScannerData((prev) => [...prev, data]);
      }
    },
    0,
    () => {
      toast.error('Pominięto skanowanie ponieważ poprzednie się nie skończyło');
    }
  );

  return (
    <>
      <div>
        <h1>Skaner test</h1>
        <p>Wynik skanowania: {scannerData.length}</p>
      </div>
      <pre>
        {scannerData.map((el, i) => (
          <div key={i}>
            #{i + 1}: {el}
          </div>
        ))}
      </pre>
    </>
  );
}
