import { createApi } from '@reduxjs/toolkit/query/react';
import { refreshableBaseQuery } from './ApiHelpers';
import {
  DocumentType,
  DocumentTypeList,
  GenericApiPlatformCollectionQueryParameters,
  GenericApiPlatformCollectionResponse
} from './Types';

export const documentTypesApi = createApi({
  reducerPath: 'documentType',
  baseQuery: refreshableBaseQuery,
  tagTypes: ['DocumentType'],
  endpoints: (builder) => ({
    getPaginatedList: builder.query<
      GenericApiPlatformCollectionResponse<DocumentTypeList>,
      GenericApiPlatformCollectionQueryParameters
    >({
      query(params) {
        return {
          url: '/warehouse/document-types',
          params
        };
      }
    }),
    getAll: builder.query<GenericApiPlatformCollectionResponse<DocumentTypeList>, null>({
      query() {
        return {
          url: '/warehouse/document-types?page=1&limit=1000'
        };
      }
    }),
    getItem: builder.query<DocumentType, { id: string }>({
      query(params) {
        return {
          url: `/warehouse/document-types/${params.id}?disableCustomFields=true`
        };
      }
    })
  })
});

export const {
  useGetPaginatedListQuery: useGetDocumentTypePaginatedListQuery,
  useGetAllQuery: useGetDocumentTypeAllQuery,
  useGetItemQuery: useGetDocumentTypeItemQuery
} = documentTypesApi;
