import { ApiPlatformRecordResponse, ProductType, ProductWarehouse } from '../../../redux/api/Types';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import Modal from '@mui/material/Modal/Modal';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import CardActions from '@mui/material/CardActions/CardActions';
import IconButton from '@mui/material/IconButton/IconButton';
import { CheckCircle, FilterAlt, HighlightOff } from '@mui/icons-material';
import {
  Avatar,
  ButtonGroup,
  CircularProgress,
  InputAdornment,
  List,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { productsApi, useGetAllWarehouseProductsQuery } from '../../../redux/api/ProductsApi';
import InfiniteScroll from 'react-infinite-scroll-component';

import ProductTypeListPicker from './ProductTypeListPicker';
import { useWarehouseProducts } from '../../../hooks/WarehouseProducts';

export interface SingleApiProductListPickerProps<T extends ApiPlatformRecordResponse> {
  title: string;
  defaultValue: T | null;
  onChange: (value: T | null) => void;
  onClose?: () => void;
  onCancel?: () => void;
  label: (value: T | null) => string | JSX.Element;
  placeholder: string;
  openByDefault?: boolean;
  children?: JSX.Element;
  defaultFilters?: Partial<SingleApiProductsListFilterOptions>;
}

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'open'
})<ListItemProps>(({ theme }) => ({
  borderBottom: '1px solid '.concat(theme.palette.divider),
  cursor: 'pointer'
}));

interface SingleApiProductListPickerOptionItemProps {
  value: string;
  selected: boolean;
  onClick: () => void;
  label: string | JSX.Element;
  onDoubleClick: () => void;
}

function SingleApiProductListPickerOptionItem(props: SingleApiProductListPickerOptionItemProps) {
  const { value, selected, label, onClick, onDoubleClick } = props;
  return (
    <ListItem
      key={value}
      style={{ ...(selected ? { background: 'rgba(46, 125, 50, 0.4)' } : {}) }}
      alignItems="flex-start"
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      <ListItemText primary={label} />
    </ListItem>
  );
}

type SingleApiProductsListFilterOptions = {
  name?: string | null;
  type?: ProductType | null;
};

export default function SingleApiProductListPicker(
  props: SingleApiProductListPickerProps<ProductWarehouse>
) {
  const [value, setValue] = useState<string | null>(props.defaultValue?.['@id'] ?? null);
  const [pickerOpened, setPickerOpened] = useState<boolean>(props.openByDefault ?? false);
  const [delay, setDelay] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<SingleApiProductsListFilterOptions>(
    props.defaultFilters ?? {}
  );
  const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
  const { isLoading, products } = useWarehouseProducts();

  useEffect(() => {
    setValue(props.defaultValue?.['@id'] ?? null);
  }, [props.defaultValue?.['@id'] ?? null]);

  const filteredProducts = useMemo(() => {
    let filtered: ProductWarehouse[] = products;
    if (filters.name) {
      filtered = filtered.filter((product) =>
        (product.name ?? '').toLowerCase().includes((filters.name ?? '').toLowerCase())
      );
    }
    if (filters.type) {
      filtered = filtered.filter((product) => product.type?.id === filters.type?.id);
    }
    return filtered;
  }, [products, filters]);

  useEffect(() => {
    if (!pickerOpened) {
      props.onClose?.();
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          name: undefined,
          type: props.defaultFilters?.type ?? undefined
        };
      });
      setPage(1);
      setFiltersOpened(false);
      setValue(props.defaultValue?.['@id'] ?? null);
    }
  }, [pickerOpened]);

  const selectedOption = products.find((option) => option['@id'] === value) ?? null;

  const pickValue = (value: ProductWarehouse, save: boolean = false) => {
    setValue(value['@id']);
    if (save) {
      props.onChange(value);
      setPickerOpened(false);
    }
  };
  const onCancel = () => {
    setValue(props.defaultValue?.['@id'] ?? null);
    setPickerOpened(false);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        name: undefined,
        type: props.defaultFilters?.type ?? undefined
      };
    });
    setFiltersOpened(false);
    setPage(1);
    props.onCancel?.();
  };

  const onAccept = () => {
    props.onChange(selectedOption);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        name: undefined,
        type: props.defaultFilters?.type ?? undefined
      };
    });
    setPage(1);
    setFiltersOpened(false);
    setPickerOpened(false);
  };

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDelay(true);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        name: event.target.value
      };
    });
    if (window.productsTimeout) {
      clearTimeout(window.productsTimeout);
    }
    window.productsTimeout = setTimeout(() => {
      setDelay(false);
    }, 500);
  };

  const handleFilterProductTypeChange = (value: ProductType | null) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        type: value
      };
    });
  };

  return (
    <>
      <Modal open={pickerOpened} onClose={() => onCancel()}>
        <Box bgcolor="#fff" position="fixed" left={0} top={0} height="100vh" width="100vw">
          <Card sx={{ minWidth: '100%', height: '100%' }} style={{ position: 'relative' }}>
            <CardHeader
              sx={{ padding: 1 }}
              action={
                <IconButton aria-label="settings" onClick={() => onCancel()}>
                  <HighlightOff />
                </IconButton>
              }
              title={props.title}
            />
            <TextField
              placeholder={'Szukaj...'}
              value={filters.name ?? ''}
              onChange={handleSearchTextChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterAlt onClick={() => setFiltersOpened(!filtersOpened)} />
                  </InputAdornment>
                ),
                endAdornment:
                  delay || isLoading ? (
                    <InputAdornment position="start">
                      <CircularProgress size={16} />
                    </InputAdornment>
                  ) : null
              }}
            />
            {pickerOpened && (
              <>
                {filtersOpened && (
                  <CardContent
                    sx={{
                      p: 0,
                      borderBottom: (theme) => `3px solid ${theme.palette.primary.main}`
                    }}>
                    <Typography textAlign="center" variant="body2" sx={{ p: 1 }}>
                      Filtry zaawansowane
                    </Typography>
                    <List sx={{ width: '100%', padding: 0, paddingBottom: 0 }}>
                      <ListItem key={value} alignItems="flex-start">
                        <ListItemText primary={'Typ towaru:'} />
                        <ListItemText
                          primary={
                            <ProductTypeListPicker
                              defaultValue={filters.type ?? null}
                              clearable={true}
                              placeholder={'Wybierz typ'}
                              onChange={handleFilterProductTypeChange}
                            />
                          }
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                )}
                <CardContent
                  sx={{ overflowY: 'auto', p: 0 }}
                  style={{ height: `calc(100vh - 48px - 48px)`, maxHeight: '84%' }}>
                  <List sx={{ width: '100%', padding: 0, paddingBottom: 5 }}>
                    <InfiniteScroll
                      dataLength={filteredProducts.slice(0, page * 10).length} //This is important field to render the next data
                      next={() => setPage(page + 1)}
                      hasMore={page * 20 < filteredProducts.length}
                      loader={<></>}
                      height={'calc(100vh - 170px)'}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <Typography variant="body2">
                            To już wszystkie wyniki, jeśli szukałeś czegoś innego spróbuj zmienić
                            parametry wyszukiwania
                          </Typography>
                        </p>
                      }>
                      {filteredProducts.slice(0, page * 10).map((option) => (
                        <SingleApiProductListPickerOptionItem
                          key={option['@id']}
                          value={option['@id']}
                          selected={value === option['@id']}
                          onClick={() => pickValue(option)}
                          onDoubleClick={() => pickValue(option, true)}
                          label={props.label(option)}
                        />
                      ))}
                    </InfiniteScroll>
                  </List>
                </CardContent>
              </>
            )}
            <CardActions
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                top: 'initial',
                zIndex: 99,
                background: '#fff'
              }}>
              <ButtonGroup sx={{ width: '100%' }} size="small">
                <Button sx={{ width: '40%' }} color="error" onClick={onCancel}>
                  Anuluj
                </Button>
                <Button
                  sx={{ width: '100%' }}
                  color="success"
                  variant="contained"
                  onClick={onAccept}>
                  Zatwierdź
                </Button>
              </ButtonGroup>
            </CardActions>
          </Card>
        </Box>
      </Modal>
      <div onClick={() => setPickerOpened(true)}>{props.children}</div>
    </>
  );
}

SingleApiProductListPicker.defaultProps = {
  title: 'Wybierz wartość',
  defaultValue: null,
  options: [],
  onChange: () => {},
  label: (value: ApiPlatformRecordResponse) => value.relationFieldLabel ?? '',
  placeholder: 'Wybierz wartość',
  openByDefault: false
};
