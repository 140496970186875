import { ApiPlatformRecordResponse, Contractor } from '../../../redux/api/Types';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal/Modal';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';
import Card from '@mui/material/Card/Card';
import CardContent from '@mui/material/CardContent/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader/CardHeader';
import CardActions from '@mui/material/CardActions/CardActions';
import IconButton from '@mui/material/IconButton/IconButton';
import { CheckCircle, HighlightOff } from '@mui/icons-material';
import {
  Avatar,
  ButtonGroup,
  CircularProgress,
  InputAdornment,
  List,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { contractorsApi } from '../../../redux/api/ContractorsApi';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface SingleApiContractorListPickerProps<T extends ApiPlatformRecordResponse> {
  title: string;
  defaultValue: T | null;
  onChange: (value: T | null) => void;
  onClose?: () => void;
  onCancel?: () => void;
  label: (value: T | null) => string | JSX.Element;
  placeholder: string;
  openByDefault?: boolean;
  disabled?: boolean;
  children?: JSX.Element;
}

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'open'
})<ListItemProps>(({ theme }) => ({
  borderBottom: '1px solid '.concat(theme.palette.divider),
  cursor: 'pointer'
}));

interface SingleApiContractorListPickerOptionItemProps {
  value: string;
  selected: boolean;
  onClick: () => void;
  label: string | JSX.Element;
  onDoubleClick: () => void;
}

function SingleApiContractorListPickerOptionItem(
  props: SingleApiContractorListPickerOptionItemProps
) {
  const { value, selected, label, onClick, onDoubleClick } = props;
  return (
    <ListItem
      key={value}
      style={{ ...(selected ? { background: 'rgba(46, 125, 50, 0.4)' } : {}) }}
      alignItems="flex-start"
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      <ListItemText primary={label} />
    </ListItem>
  );
}

export default function SingleApiContractorListPicker(
  props: SingleApiContractorListPickerProps<Contractor>
) {
  const [selectedOption, setSelectedOption] = useState<Contractor | null>(
    props.defaultValue ?? null
  );
  const [value, setValue] = useState<string | null>(props.defaultValue?.['@id'] ?? null);
  const [pickerOpened, setPickerOpened] = useState<boolean>(props.openByDefault ?? false);
  const [searchText, setSearchText] = useState<string>('');
  const [delay, setDelay] = useState<boolean>(false);
  const [contractors, setContractors] = useState<Contractor[]>([]);
  const [page, setPage] = useState<number>(1);
  const { isLoading, isFetching, data, refetch, isUninitialized } =
    contractorsApi.endpoints?.getPaginatedList.useQuery(
      { name: searchText, page: page },
      { skip: delay }
    );

  useEffect(() => {
    setValue(props.defaultValue?.['@id'] ?? null);
    setSelectedOption(props.defaultValue ?? null);
  }, [props.defaultValue?.['@id'] ?? null]);

  useEffect(() => {
    setPage(1);
    setContractors([]);
  }, [searchText]);

  useEffect(() => {
    if (pickerOpened) {
      setContractors((prev) => {
        return [...prev, ...(data?.['hydra:member'] ?? [])];
      });
    }
  }, [JSON.stringify(data?.['hydra:member'] ?? []), pickerOpened]);

  useEffect(() => {
    if (!pickerOpened) {
      props.onClose?.();
      setSearchText('');
      setValue(props.defaultValue?.['@id'] ?? null);
    }
  }, [pickerOpened]);

  const pickValue = (value: Contractor, save: boolean = false) => {
    setSelectedOption(value);
    setValue(value['@id']);
    if (save) {
      onAccept();
    }
  };
  const onCancel = () => {
    setValue(props.defaultValue?.['@id'] ?? null);
    setPickerOpened(false);
    setSearchText('');
    setPage(1);
    setContractors([]);
    props.onCancel?.();
  };

  const onAccept = () => {
    props.onChange(selectedOption);
    setSearchText('');
    setPage(1);
    setContractors([]);
    setPickerOpened(false);
  };

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDelay(true);
    setSearchText(event.target.value);
    if (window.contractorsTimeout) {
      clearTimeout(window.contractorsTimeout);
    }
    window.contractorsTimeout = setTimeout(() => {
      setDelay(false);
    }, 500);
  };

  return (
    <>
      <Modal open={pickerOpened} onClose={() => onCancel()}>
        <Box bgcolor="#fff" position="fixed" left={0} top={0} height="100vh" width="100vw">
          <Card sx={{ minWidth: '100%', height: '100%' }} style={{ position: 'relative' }}>
            <CardHeader
              sx={{ padding: 1 }}
              action={
                <IconButton aria-label="settings" onClick={() => onCancel()}>
                  <HighlightOff />
                </IconButton>
              }
              title={props.title}
            />
            <TextField
              placeholder={'Szukaj...'}
              value={searchText}
              onChange={handleSearchTextChange}
              InputProps={{
                endAdornment:
                  delay || isLoading || isFetching ? (
                    <InputAdornment position="start">
                      <CircularProgress size={16} />
                    </InputAdornment>
                  ) : null
              }}
            />
            {pickerOpened && (
              <CardContent
                sx={{ overflowY: 'auto', p: 0 }}
                style={{ height: `calc(100vh - 48px - 48px)`, maxHeight: '84%' }}>
                <List sx={{ width: '100%', padding: 0, paddingBottom: 5 }}>
                  <InfiniteScroll
                    dataLength={contractors.length} //This is important field to render the next data
                    next={() => setPage(page + 1)}
                    hasMore={data?.['hydra:view']?.['hydra:next'] !== undefined}
                    loader={<>{(isFetching || isLoading) && <h4>Pobieram...</h4>}</>}
                    height={'calc(100vh - 150px)'}
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        <Typography variant="body2">
                          To już wszystkie wyniki, jeśli szukałeś czegoś innego spróbuj zmienić
                          parametry wyszukiwania
                        </Typography>
                      </p>
                    }>
                    {contractors.map((option) => (
                      <SingleApiContractorListPickerOptionItem
                        key={option['@id']}
                        value={option['@id']}
                        selected={value === option['@id']}
                        onClick={() => pickValue(option)}
                        onDoubleClick={() => pickValue(option, true)}
                        label={props.label(option)}
                      />
                    ))}
                  </InfiniteScroll>
                </List>
              </CardContent>
            )}
            <CardActions
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                top: 'initial',
                zIndex: 99,
                background: '#fff'
              }}>
              <ButtonGroup sx={{ width: '100%' }} size="small">
                <Button sx={{ width: '40%' }} color="error" onClick={onCancel}>
                  Anuluj
                </Button>
                <Button
                  sx={{ width: '100%' }}
                  color="success"
                  variant="contained"
                  onClick={onAccept}>
                  Zatwierdź
                </Button>
              </ButtonGroup>
            </CardActions>
          </Card>
        </Box>
      </Modal>
      <div onClick={() => setPickerOpened(!props.disabled)}>
        {selectedOption && (
          <SingleApiContractorListPickerOptionItem
            onClick={() => false}
            onDoubleClick={() => false}
            label={props.label(selectedOption)}
            value={selectedOption?.['@id'] ?? null}
            selected={false}
          />
        )}
        {!selectedOption && (props.children ?? props.placeholder)}
      </div>
    </>
  );
}

SingleApiContractorListPicker.defaultProps = {
  title: 'Wybierz wartość',
  defaultValue: null,
  options: [],
  onChange: () => {},
  label: (value: ApiPlatformRecordResponse) => value.relationFieldLabel ?? '',
  placeholder: 'Wybierz wartość',
  openByDefault: false
};
