export const getDocumentTypeColor = (documentName: string): string => {
  let hash = 0;

  documentName.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  let colour: string = '#';

  for (let i: number = 0; i < 3; i++) {
    const value: number = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }

  return colour;
};
