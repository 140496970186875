import SingleListPicker, { SingleListPickerProps } from '../Common/SingleListPicker';
import { Place } from '../../../redux/api/Types';
import { useAppSelector } from '../../../redux/Store';
import { warehousesApi } from '../../../redux/api/WarehousesApi';
import * as React from 'react';

export default function PlaceSinglePicker(props: Partial<SingleListPickerProps<Place>>) {
  const warehouse = useAppSelector((state) => state.appContext.warehouse?.['@id']);
  const {
    isLoading,
    isFetching,
    data: placesData
  } = warehousesApi.endpoints?.getPlaces.useQuery({ warehouseId: warehouse }, { skip: !warehouse });

  return (
    <SingleListPicker<Place>
      {...props}
      defaultValue={props.defaultValue ?? null}
      parseOptionToString={(item) => item.name}
      options={placesData?.['hydra:member'] ?? []}
      onChange={props.onChange}
      placeholder="Wybierz miejsce"
      title="Wybierz miejsce"
      label={(item) => (!item ? '' : item.name)}
    />
  );
}
