import { createApi } from '@reduxjs/toolkit/query/react';
import { refreshableBaseQuery } from './ApiHelpers';
import { logout } from '../features/UserSlice';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: refreshableBaseQuery,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getMyPermissions: builder.query<string[], null>({
      query() {
        return {
          url: 'employee/my/permissions'
        };
      },
      transformResponse: (result: any) => result.permissions,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(logout());
          console.error(error);
        }
      }
    })
  })
});
