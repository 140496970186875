import Typography from '@mui/material/Typography';
import SingleListPicker, { SingleListPickerProps } from './SingleListPicker';
import * as React from 'react';
import { DocumentTypeList } from '../../../redux/api/Types';
import { useGetDocumentTypeAllQuery } from '../../../redux/api/DocumentTypesApi';
import { getDocumentTypeColor } from '../../../utils/Colors';
import { Box } from '@mui/material';

export default function DocumentTypeListPicker(
  props: Partial<SingleListPickerProps<DocumentTypeList>>
) {
  const { isLoading, isFetching, data } = useGetDocumentTypeAllQuery(null);
  return (
    <SingleListPicker<DocumentTypeList>
      {...props}
      options={data?.['hydra:member'] ?? []}
      placeholder="Typ"
      parseOptionToString={(item) => `${item.name} ${item.code}`}
      label={(item) =>
        !item ? (
          ''
        ) : (
          <div>
            <Box sx={{ backgroundColor: getDocumentTypeColor(item.name), color: '#fff', p: 1 }}>
              {item.code} - {item.name}
            </Box>
          </div>
        )
      }
      selectedLabel={(item) =>
        !item ? (
          ''
        ) : (
          <div>
            <Box sx={{ backgroundColor: getDocumentTypeColor(item.name), color: '#fff', p: 1 }}>
              {item.code}
            </Box>
          </div>
        )
      }
    />
  );
}
